/** @jsx jsx */
import { jsx } from "theme-ui";
import TopicTeaser from "../entities/topic/topicTeaser";
import Container from "../container";

const CategoryTopicDisplay = ({ value }) => {
  return (
    <div className="category-topic-display">
      <Container>
        {value.category && (
          <h2
            sx={{
              fontSize: ["2rem", "2.5rem"],
              lineHeight: "1.2",
              margin: "0px 0px 40px 0px",
            }}
          >
            {value.category.name}
          </h2>
        )}
        <ul
          sx={{
            display: "flex",
            flexWrap: "wrap",
            margin: "0px",
            padding: "0px",
          }}
        >
          {value.topics.map((topic, index) => (
            <li
              key={index}
              sx={{
                display: "flex",
                listStyle: "none",
                width: ["100%", "calc(100% / 2)", "calc(100% / 3)"],
                boxSizing: "border-box",
                marginBottom: ["30px", "40px"],
              }}
            >
              <TopicTeaser topic={topic} />
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};

export default CategoryTopicDisplay;
