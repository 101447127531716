/** @jsx jsx */
import { jsx } from "theme-ui";

const Container = ({ children }) => {
  return (
    <div
      className="container"
      sx={{
        maxWidth: ["800px", "1000px", "1200px", "1234px"],
        padding: ["0px 5%", "0px 5%", "0px 5%"],
        margin: "0 auto",
        boxSizing: "content-box",
        ".container": {
          maxWidth: "100%",
          padding: "0px",
          margin: "0",
        },
      }}
    >
      {children}
    </div>
  );
};

export default Container;
