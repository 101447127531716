/** @jsx jsx */
import { jsx } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../../sanityConfig";
import { getGatsbyImageData } from "gatsby-source-sanity";
import PlaceHolder from "../../../images/Placeholder2.svg";
import { PortableText } from "@portabletext/react";
import PortableTextComponent from "../../serializers/portableTextComponents";
import { Link } from "gatsby";

const PropertyTeaser = ({ entity }) => {
  console.log(entity);
  let main_image = null;
  if (entity.main_image) {
    main_image = getGatsbyImageData(
      entity.main_image,
      { width: 400, height: 250 },
      sanityConfig
    );
  } else if (entity.mainImage) {
    main_image = getGatsbyImageData(
      entity.mainImage,
      { width: 400, height: 250 },
      sanityConfig
    );
  } else if (entity._rawMainImage) {
    main_image = getGatsbyImageData(
      entity._rawMainImage,
      { width: 400, height: 250 },
      sanityConfig
    );
  }
  return (
    <Link
      to={entity.slug ? "/property/" + entity.slug.current : ""}
      sx={{
        borderRadius: "15px",
        position: "relative",
        background: "lightGray",
        textDecoration: "none",
        display: "block",
        p: {
          color: "#262626",
        },
        "&:before": {
          content: "''",
          background: "#fff",
          position: "absolute",
          top: "4px",
          left: "4px",
          right: "4px",
          bottom: "4px",
          borderRadius: "15px",
        },
        ".shop-teaser": {
          fontSize: "0.875rem",
          borderRadius: "1.5rem",
          color: "darkGray",
          textDecoration: "none",
          cursor: "pointer",
          lineHeight: "1.5rem",
          display: "inline-block",
          padding: "0.7rem 3.5rem",
          lineHeight: "1.5rem",
          position: "relative",
          fontWeight: 600,
          background:
            "linear-gradient(to right, #0b5d57 0%, #00a998 98%, #00a998 100%)",
          "&:before": {
            content: "''",
            position: "absolute",
            top: "2px",
            left: "2px",
            right: "2px",
            bottom: "2px",
            borderRadius: "1.5rem",
            background: "white",
          },
          ":hover": {
            color: "white",
            "&:before": {
              display: "none",
            },
            background:
              "linear-gradient(to right, #f0791d 0%, #ba101d 98%, #ba101d 100%)",
            boxShadow: "0px 15px 30px rgb(0 169 152 / 27%)",
          },
        },
        "&:hover": {
          background:
            "linear-gradient(to right, #f0791d 0%, #ba101d 98%, #ba101d 100%)",
          ".shop-teaser": {
            fontSize: "0.875rem",
            borderRadius: "1.5rem",
            color: "white",
            textDecoration: "none",
            cursor: "pointer",
            lineHeight: "1.5rem",
            display: "inline-block",
            padding: "0.7rem 3.5rem",
            lineHeight: "1.5rem",
            fontWeight: 600,
            background:
              "linear-gradient(to right, #0b5d57 0%, #00a998 98%, #00a998 100%)",
            boxShadow: "0px 15px 30px rgb(0 169 152 / 27%)",
            ":hover": {
              bg: "primaryDark",
            },
            "&:before": {
              display: "none",
            },
          },
        },
      }}
    >
      <div
        sx={{
          padding: "20px",
          position: "relative",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          img: {
            borderRadius: "15px",
          },
        }}
      >
        {main_image && <GatsbyImage image={main_image} />}
        {!main_image && <img src={PlaceHolder} />}

        <h3
          sx={{
            margin: "5px 0px 5px 0px",
            color: "darkGray",
            fontSize: "16px",
            textWrap: "nowrap",
            overflow: "hidden",
          }}
        >
          {entity.headline}
        </h3>
        <div
          sx={{
            padding: "5px 10px 0px 10px",
            // flexGrow: "1",
            fontSize: "12px",
            color: "darkGray",
            textWrap: "nowrap",
            overflow: "hidden",
          }}
        >
          {entity.address}
        </div>
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "40px",
            justifyContent: "center",
          }}
        >
          <div
            sx={{
              color: "primary",
              variant: "buttons.primarySolid",
              display: "inline-block",
              marginTop:"10px",
            }}
            className="learn-teaser"
          >
            Learn More
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PropertyTeaser;
