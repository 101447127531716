import * as React from "react";
import { Helmet } from "react-helmet";

const Embed = ({ value }) => {
  if (value.embed) {
    if (value.embed.includes("woobox")) {
      var woobox = true;
    }
    if (value.embed.includes("wistia")) {
      var wistia = true;
    }
    if (value.embed.includes("typeform")) {
      var typeform = true;
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        {woobox && (
          <script
            id="woobox-sdk"
            src="https://woobox.com/js/plugins/woo.js"
          ></script>
        )}
        {wistia && (
          <script
            src="https://fast.wistia.com/embed/medias/dtocrsa9m0.jsonp"
            async
          ></script>
        )}
        {wistia && (
          <script
            src="https://fast.wistia.com/assets/external/E-v1.js"
            async
          ></script>
        )}
        {typeform && (
          <script
            id="typef_orm"
            src="https://embed.typeform.com/embed.js"
          ></script>
        )}
      </Helmet>
      <div
        key={`body`}
        id="___gatsby"
        dangerouslySetInnerHTML={{ __html: value.embed }}
      />
    </React.Fragment>
  );
};

export default Embed;
