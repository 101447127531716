/** @jsx jsx */
import { jsx } from "theme-ui";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Burger from "./burger";
import { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Slider } from "react-typeform-embed";

const Menu = () => {
  const [subMenuState, setSubMenuState] = useState([false, false]);
  const [menuState, setMenuState] = useState(false);
  const toggleMenu = () => {
    let newState = !menuState;
    setMenuState(newState);
  };

  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          submenu: sanityPageDefinition(slug: { current: { eq: "topics" } }) {
            _rawEntities(resolveReferences: { maxDepth: 10 })
          }
          facebook: file(name: { eq: "facebook-white" }) {
            publicURL
          }
          linkedin: file(name: { eq: "linkedin-white" }) {
            publicURL
          }
          instagram: file(name: { eq: "instagram-white" }) {
            publicURL
          }
          youtube: file(name: { eq: "youtube-white" }) {
            publicURL
          }
          logolight: file(name: { eq: "logolight" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          siteSettings: sanitySiteSettings {
            primaryColor
            logo {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      `}
      render={(data) => (
        <div
          sx={{
            padding: "0px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginLeft: "0px",
            padding: ["15px", "15px", "0px"],
            marginLeft: ["0px", "0px", "20px"],
            backdropFilter: [
              menuState && "blur(10px)",
              menuState && "blur(10px)",
              "none",
            ],
            backgroundColor: [
              menuState && "rgba(0,0,0,0.6)",
              menuState && "rgba(0,0,0,0.6)",
              "transparent",
            ],
          }}
          className={menuState && "mobile-menu-open"}
        >
          <Link
            sx={{
              width: ["200px"],
              display: "flex",
              img: {
                height: "auto",
              },
            }}
            to="/"
          >
            <GatsbyImage
              image={data.logolight.childImageSharp.gatsbyImageData}
              className="logo-light"
            />
          </Link>
          <Burger clickMe={(e) => toggleMenu()} open={menuState} />
          <div
            sx={{
              width: ["100%", "100%", "calc(100% - 225px)"],
              display: [
                menuState ? "block" : "none",
                menuState ? "block" : "none",
                "flex",
              ],
              flexDirection: ["column"],
              alignItems: "flex-end",
              justifyContent: "space-around",
            }}
          >
            <div
              sx={{
                display: "flex",
                justifyContent: ["center", "center", "space-between"],
                padding: ["10px 0px", "10px 0px", "0px"],
                marginBottom: "15px",
                alignItems: "center",
                flexWrap: ["wrap", "wrap", "nowrap"],
                ".header-email": {
                  color: "white",
                  textDecoration: "none",
                  fontSize: "13px",
                  borderRight: "thin solid white",
                  marginRight: "15px",
                  paddingRight: "15px",
                  ".header-phone": {
                    color: "white",
                    textDecoration: "none",
                    fontSize: "13px",
                    borderRight: "thin solid white",
                    marginRight: "15px",
                    paddingRight: "15px",
                    borderRight: ["0px", "0px", "thin solid white"],
                  },
                  "&:hover": {
                    textDecoration: "underline",
                  },
                },
                ".header-phone": {
                  color: "white",
                  textDecoration: "none",
                  fontSize: "13px",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                },
                ".header-social-container": {
                  display: "flex",
                  alignItems: "center",
                  width: ["100%", "100%", "auto"],
                  justifyContent: "center",
                  marginTop: ["20px", "20px", "0px"],
                  a: {
                    marginLeft: "10px",
                    display: "flex",
                    "&:nth-child(1)": {
                      marginLeft: "0px",
                    },
                  },
                },
              }}
            >
              <a className="header-email" href="mailto:info@stlch.com">
                info@stlch.com
              </a>
              <a className="header-phone" href="tel:314-800-5773">
                314-800-5773
              </a>
            </div>
            <nav
              aria-label="Corporate Housing St. Louis Missouri"
              sx={{
                display: ["block"],
              }}
            >
              <ul
                role="menubar"
                aria-label="Corporate Housing St. Louis Missouri"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: ["column", "column", "row"],
                  margin: "0px",
                  padding: ["20px 0px", "20px 0px", "0px"],
                  li: {
                    listStyle: "none",
                    lineHeight: "1.5",
                    marginLeft: ["0px", "0px", "24px"],
                    padding: ["10px 0px", "10px 0px", "0px"],
                    width: ["100%", "100%", "auto"],
                    textAlign: "center",
                    position: "relative",
                    "> div.submenu-container": {
                      display: ["block", "block", "none"],
                      position: ["relative", "relative", "absolute"],
                      paddingTop: ["0px", "0px", "20px"],
                      width: ["100%", "100%", "150px"],
                      left: ["auto", "auto", "auto"],
                      ".submenu-container": {
                        left: ["auto", "auto", "150px"],
                        top: ["auto", "auto", "-20px"],
                      },
                    },
                    "&:hover > div.submenu-container": {
                      display: "block",
                      visibility: "visible",
                      opacity: 1,
                    },
                    ul: {
                      padding: ["10px 0px", "10px 0px", "0px"],
                      margin: "0px",
                      backdropFilter: ["none", "none", "blur(10px)"],
                      backgroundColor: [
                        "transparent",
                        "transparent",
                        "rgba(0,0,0,0.6)",
                      ],
                      li: {
                        padding: "0px",
                        margin: "0px",
                        a: {
                          color: ["#ffffff", "grayMed", "grayMed"],
                          textDecoration: "none",
                          display: "block",
                          padding: ["5px 0px", "10px 0px"],
                          // borderBottom: ["none", "none", "thin solid white"],
                          fontSize: ["12px", "12px", "inherit"],
                          margin: ["0px", "0px", "0px 5px"],
                        },
                        "&:nth-child(1)": {
                          a: {
                            // borderTop: ["none", "none", "thin solid white"],
                          },
                        },
                      },
                    },
                    a: {
                      color: "white",
                      textDecoration: "none",
                      fontSize: ["22px", "22px", "15px"],
                      fontWeight: "600",
                    },
                  },
                }}
              >
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                  }}
                >
                  <Link to="/">Home</Link>
                </li>
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                  }}
                >
                  <Link
                    to="/what-is-corporate-housing"
                    title="Corporate Housing is a catch-all term for short term rental of a furnished apartment, condo, or home on a seasonal, short-term or temporary basis. Where a traditional hotel or an extended hotel can be impersonal and costly, corporate housing is typically fully furnished and cost effective."
                  >
                    What is Corporate Housing?
                  </Link>
                  <div className="submenu-container">
                    <ul className="submenu">
                      <li className="submenu-link">
                        <Link to="/details">Details</Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/what-we-include">See Everything That's Included</Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/contact-us">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="menuitem" role="none">
                  <Link to="/furnished-apartments">Furnished Apartments</Link>
                  <div className="submenu-container">
                    <ul className="submenu">
                      <li className="submenu-link">
                        <Link to="/corporate-apartments">
                          Corporate Apartments
                        </Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/st-louis-corporate-housing-furnishings">
                          Furnishings
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                  }}
                >
                  <Link to="/corporate-housing">
                    St. Louis Corporate Housing
                  </Link>
                  <div className="submenu-container">
                    <ul className="submenu">
                      <li className="submenu-link">
                        <Link to="/locations">Locations</Link>
                        <div className="submenu-container">
                          <ul className="submenu">
                            <li className="submenu-link">
                              <Link to="/ballwin">Ballwin</Link>
                            </li>
                            <li className="submenu-link">
                              <Link to="/chesterfield">Chesterfield</Link>
                            </li>
                            <li className="submenu-link">
                              <Link to="/florissant">Florissant</Link>
                            </li>
                            <li className="submenu-link">
                              <Link to="/hazelwood">Hazelwood</Link>
                            </li>
                            <li className="submenu-link">
                              <Link to="/kirkwood">Kirkwood</Link>
                            </li>
                            <li className="submenu-link">
                              <Link to="/maryland-heights">
                                Maryland Heights
                              </Link>
                            </li>
                            <li className="submenu-link">
                              <Link to="/mehlville">Mehlville</Link>
                            </li>
                            <li className="submenu-link">
                              <Link to="/oakville">Oakville</Link>
                            </li>
                            <li className="submenu-link">
                              <Link to="/university-city">University City</Link>
                            </li>
                            <li className="submenu-link">
                              <Link to="/wildwood">Wildwood</Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                    a: {
                      color: "#190A32 !important",
                      textDecoration: "none",
                      display: "inline-block",
                      fontSize: "0.875rem",
                      borderRadius: "1.5rem",
                      bg: "white",
                      padding: [".2rem .8rem", ".5rem 1.2rem"],
                      textDecoration: "none",
                      fontStyle: "normal",
                      cursor: "pointer",
                      fontWeight: "bold",
                      border: "none",
                      outline: "inherit",
                      ":hover": {
                        color: "white !important",
                        background:
                          "linear-gradient(to right, #f0791d 0%, #ba101d 98%, #ba101d 100%)",
                        textDecoration: "none !important",
                      },
                    },
                  }}
                >
                  <Link to="/get-started">Get Started</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    />
  );
};

export default Menu;
