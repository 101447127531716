/** @jsx jsx */
import { jsx } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../sanityConfig";
import { getGatsbyImageData } from "gatsby-source-sanity";
import PortableTextComponent from "../serializers/portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Hero = ({ value }) => {
  const gatsbyImageData = getGatsbyImageData(
    value.image,
    { maxWidth: 1100 },
    sanityConfig
  );
  const breakpoints = useBreakpoint();
  return (
    <div id={value.id ? value.id : ""}>
      <Container>
        <div
          sx={{
            position: "relative",
            zIndex: "2",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: ["column", "row"],
            padding: [
              "120px 0px 60px 0px",
              "120px 0px 60px 0px",
              "165px 0px 145px 0px",
            ],
          }}
        >
          <div
            sx={{
              width: ["100%", "100%", "500px"],
            }}
          >
            <h2
              sx={{
                fontSize: ["2rem", "2.5rem"],
                margin: "0px 0px 10px 0px",
                lineHeight: ["2.9rem", "3.4rem"],
                maxWidth: "480px",
              }}
            >
              {value.title}
            </h2>
            <div
              sx={{
                color: "textGray",
                fontSize: "body",
                lineHeight: "2.2rem",
                ".cta": {
                  lineHeight: "1.5",
                },
              }}
              className="hero-copy-container"
            >
              <PortableText
                value={value.body}
                components={PortableTextComponent}
              />
            </div>
          </div>

          <div
            className="hero-image-container"
            sx={{
              width: ["100%", "100%", "550px"],
              position: "relative",
              display: "flex",
              alignItems: "center",
              marginRight: "32px",
              marginLeft: "50px",
              ".gatsby-image-wrapper": {
                width: "100%",
                overflow: "visible",
                "> div": {
                  width: "100%",
                  maxWidth: "100% !important",
                },
              },
              img: {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <GatsbyImage
              sx={{
                position: "absolute",
              }}
              image={gatsbyImageData}
            />
          </div>
        </div>
        <div
          sx={{
            display: ["block", "block", "none"],
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            opacity: "0.7",
            background:
              "linear-gradient(to top, #0b5d57 0%, #000000 98%, #000000 100%)",
          }}
        ></div>
      </Container>
    </div>
  );
};

export default Hero;
