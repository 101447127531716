import * as React from "react";

import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import sanityConfig from "../../sanityConfig";
const Image = ({ value }) => {
  const gatsbyImageData = getGatsbyImageData(
    value,
    { maxWidth: 675 },
    sanityConfig
  );
  return <GatsbyImage image={gatsbyImageData} />;
};

export default Image;
