/** @jsx jsx */
import { jsx } from "theme-ui";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";

const List = ({ value }) => {
  return (
    <div id={value.id ? value.id : ""}>
      <Container>
        <ul
          sx={{
            padding: "0px",
            margin: "0px",
          }}
        >
          {value.entities &&
            value.entities.map((entity, index) => (
              <li
                key={index}
                sx={{
                  listStyle: "none",
                }}
              >
                <PortableText
                  value={entity}
                  components={PortableTextComponent}
                />
              </li>
            ))}
        </ul>
      </Container>
    </div>
  );
};

export default List;
