/** @jsx jsx */
import { jsx } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../sanityConfig";
import { getGatsbyImageData } from "gatsby-source-sanity";
import PlaceHolder from "../../images/Placeholder2.svg";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
const Quote = ({ value }) => {
  const gatsbyTopicIconImageData = getGatsbyImageData(
    value.image,
    { maxWidth: 100 },
    sanityConfig
  );
  return (
    <div
      sx={{
        textAlign: "center",
        backgroundColor: "white",
        padding: "48px",
        borderRadius: "8px",
        height: "100%",
        boxSizing: "border-box",
        img: {
          width: "96px",
          height: "96px",
          borderRadius: "50%",
          backgroundColor: "primary",
        },
      }}
    >
      {gatsbyTopicIconImageData ? (
        <GatsbyImage image={gatsbyTopicIconImageData} />
      ) : (
        <img src={PlaceHolder} />
      )}
      {value.name && (
        <h3
          sx={{
            fontSize: "1.5rem",
            marginBottom: "10px",
          }}
        >
          {value.name}
        </h3>
      )}
      {value.title && <div>{value.title}</div>}
      {value.body && (
        <PortableText value={value.body} components={PortableTextComponent} />
      )}
    </div>
  );
};

export default Quote;
