/** @jsx jsx */
import { jsx } from "theme-ui";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";
const TextBlock = ({ value }) => {
  return (
    <div
      className="text-block"
      id={value.id ? value.id : ""}
      sx={{
        color: "textGray",
        fontSize: "body",
        p: {
          maxWidth: "900px",
          margin: "0 auto",
        },
      }}
    >
      <div
        sx={{
          maxWidth: "800px !important",
          margin: "0 auto",
        }}
      >
        <Container>
          <PortableText value={value.text} components={PortableTextComponent} />
        </Container>
      </div>
    </div>
  );
};

export default TextBlock;
