/** @jsx jsx */
import { jsx } from "theme-ui";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";
import axios from "axios";
import { useState } from "react";

const Contact = ({ value }) => {
  return (
    <div>
      <Container>
        <div
          sx={{
            display: "flex",
            flexWrap: "wrap",
            padding: "165px 0px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              width: ["100%", "100%", "calc(100% - 700px)"],
              maxWidth: ["530px", "500px", "500px"],
              color: "textGray",
              fontSize: "body",
              "h1,h2,h3,h4,h5,h6": {
                color: "primary",
                marginTop: "0px",
                marginBottom: "0px",
              },
              h1: {
                maxWidth: "480px",
                fontSize: "3.5rem",
                lineHeight: "3.4rem",
              },
            }}
          >
            <PortableText
              value={value.body}
              components={PortableTextComponent}
            />
          </div>
          <div
            sx={{
              width: ["100%", "100%", "615px"],
            }}
          >
            <div
              key={`body`}
              id="___gatsby"
              dangerouslySetInnerHTML={{
                __html: `<div data-tf-widget="cJs0hbEQ" data-tf-opacity="100" data-tf-iframe-props="title=Contact" data-tf-transitive-search-params data-tf-medium="snippet" style="width:100%;height:500px;"></div>`,
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
