/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useState, useRef, useEffect } from "react";
import Container from "../container";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from "../../images/calendar.svg";
import { navigate } from "gatsby";

const propertyOptions = [
  { value: "brentwood", label: "Brentwood" },
  { value: "central", label: "Central" },
  { value: "central-west-end", label: "Central - West End" },
  { value: "downtown", label: "Downtown" },
  { value: "metro-east-southern-il", label: "Metro East - Southern IL" },
  { value: "st-charles-county", label: "St. Charles County" },
  { value: "west-county", label: "West County" },
];
const durationOptions = [
  { value: "30", label: "30 Days" },
  { value: "60", label: "60 Days" },
  { value: "90", label: "90+ Days" },
];

const Search = ({ value }) => {
  const [selectedProperty, setSelectedProperty] = useState({
    value: "all-properties",
  });
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  function searchClick() {
    console.log(selectedProperty);
    navigate(`/${selectedProperty.value}`);
  }
  return (
    <div>
      <Container>
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "rgba(255,255,255,1)",
            flexDirection: ["column", "row"],
            padding: "40px",
            borderRadius: "4px",
            position: "relative",
            top: "-70px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            zIndex: "2",
            "> div": {
              width: ["100%", "calc(100% / 4 - 20px)"],
              marginBottom: ["20px", "0px"],
              label: {
                fontSize: "bold",
                fontSize: "24px",
              },
            },
          }}
        >
          <div>
            <label>Locations</label>
            <Select
              defaultValue={selectedProperty}
              onChange={setSelectedProperty}
              options={propertyOptions}
            />
          </div>
          <div
            sx={{
              position: "relative",
            }}
          >
            <label>Arrival Date</label>

            <DatePicker
              sx={{
                borderColor: "hsl(0, 0%, 80%)",
                borderRadius: "4px",
                borderStyle: "solid",
                borderWidth: "1px",
                minHeight: "38px",
                display: "flex",
                alignItems: "center",
                padding: "2px 8px",
                boxSizing: "border-box",
                width: "100%",
                backgroundImage: "url(" + calendar + ")",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "calc(100% - 10px) center",
              }}
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
            />
          </div>
          <div>
            <label>Duration</label>

            <Select
              defaultValue={selectedDuration}
              onChange={setSelectedDuration}
              options={durationOptions}
            />
          </div>
          <div
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <div
              sx={{
                borderColor: "hsl(0, 0%, 80%)",
                borderRadius: "1.5rem",
                borderStyle: "solid",
                borderWidth: "1px",
                minHeight: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "2px 8px",
                boxSizing: "border-box",
                width: "100%",
                background:
                  "linear-gradient(to right, #f0791d 0%, #ba101d 98%, #ba101d 100%)",
                color: "white",
                textDecoration: "none",
                fontWeight: "bold",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={searchClick}
            >
              Search
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Search;
