/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../../sanityConfig";
import { getGatsbyImageData } from "gatsby-source-sanity";
import arrow from "../../../images/bluearrowsvg.svg";

const TopicTeaser = ({ topic }) => {
  const gatsbyTopicIconImageData = getGatsbyImageData(
    topic.icon,
    { maxWidth: 100 },
    sanityConfig
  );
  return (
    <Link
      sx={{
        display: "flex",
        color: "primary",
        alignItems: "center",
        textDecoration: "none",
        width: "100%",
        ":hover": {
          ".topic-see-more": {
            textDecoration: "underline",
          },
        },
      }}
      aria-label={"See " + topic.name + " articles"}
      to={"/" + topic.slug.current}
    >
      <div
        sx={{
          width: "100px",
          textAlign: "center",
          img: {
            height: "50px",
            width: "auto",
          },
        }}
      >
        <GatsbyImage image={gatsbyTopicIconImageData} />
      </div>
      <div>
        <h3
          sx={{
            fontSize: "1.5rem",
            margin: "0px",
          }}
        >
          {topic.name}
        </h3>
        <div
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            className="topic-see-more"
            sx={{
              fontSize: "1.125rem",
            }}
          >
            See more
          </div>
        </div>
      </div>
    </Link>
  );
};
export default TopicTeaser;
