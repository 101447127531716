/** @jsx jsx */
import { jsx } from "theme-ui";
import Container from "../container";
import { Widget } from "react-typeform-embed";

export const addActive = (id) => {
  if (typeof window !== "undefined") {
    //do work
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://stlch.activehosted.com/f/embed.php?id=" + id;
    document.getElementsByTagName("head")[0].appendChild(script);
  }
};

const LeaseTerminationForm = ({ value }) => {
  return (
    <div className="lease-termination-form" id={value.id ? value.id : ""}>
      <Container>
      <div class="_form_20 form-container">{addActive("20")}</div>
      </Container>
    </div>
  );
};

export default LeaseTerminationForm;
