import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import sanityConfig from "../../sanityConfig";
import LinkResolve from "./linkResolve";

const InlineImage = ({ value }) => {
  const gatsbyImageData = getGatsbyImageData(
    value.asset,
    { maxWidth: 675 },
    sanityConfig
  );
  return (
    <React.Fragment>
      {value.link && (
        <LinkResolve link={value.link}>
          <GatsbyImage
            image={gatsbyImageData}
            alt={value.alt}
            title={value.title}
          />
        </LinkResolve>
      )}
      {!value.link && (
        <GatsbyImage
          image={gatsbyImageData}
          alt={value.alt}
          title={value.title}
        />
      )}
    </React.Fragment>
  );
};

export default InlineImage;
