/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import Header from "./regions/header/header";
import Footer from "./regions/footer/footer";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css"; //this will show/hide the link on focus
import React from "react";
import { Helmet } from "react-helmet";

const Layout = ({ children, type }) => {
  return (
    <div className={type}>
      <SkipNavLink />
      <Header type={type} />
      <SkipNavContent />
      <main>{children}</main>
      <Footer />
      <Helmet>
        <script src="//embed.typeform.com/next/embed.js"></script>
      </Helmet>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
