/** @jsx jsx */
import { jsx } from "theme-ui";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../../sanityConfig";
import { getGatsbyImageData } from "gatsby-source-sanity";
import Container from "../../container";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import FormFooter from "./formFooter";
import LinkResolve from "../../serializers/linkResolve";
function returnSocialImage(image) {
  const gatsbyImageData = getGatsbyImageData(
    image,
    { maxWidth: 100 },
    sanityConfig
  );
  return <GatsbyImage image={gatsbyImageData} alt="Social Media" />;
}

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          facebook: file(name: { eq: "facebook-black" }) {
            publicURL
          }
          linkedin: file(name: { eq: "linkedin-black" }) {
            publicURL
          }
          instagram: file(name: { eq: "instagram-black" }) {
            publicURL
          }
          youtube: file(name: { eq: "youtube-black" }) {
            publicURL
          }
          sanityFooter {
            _rawFooterLinks(resolveReferences: { maxDepth: 10 })
            _rawSocialMedia(resolveReferences: { maxDepth: 10 })
          }
        }
      `}
      render={(data) => (
        <footer
          sx={{
            // backgroundColor: "primary",
            padding: "0px 0px 20px 0px",

            position: "relative",
          }}
        >
          <div
            sx={{
              paddingBottom: "40px",
            }}
          >
            <Container>

              <div
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div
                  sx={{
                    display: "flex",
                    width: ["100%", "40%"],
                    flexWrap: "wrap",
                    "> nav": {
                      width: ["auto", "calc(100% / 3)"],
                      marginRight: ["50px", "0px"],
                      marginBottom: ["50px", "0px"],
                      "> div": {
                        fontWeight: "600",
                        marginBottom: "20px",
                      },
                      a: {
                        color: "textGray",
                        display: "block",
                        fontWeight: "400",
                        marginBottom: "10px",
                        "&last-child": {
                          marginBottom: "0px",
                        },
                      },
                    },
                  }}
                >
                  {data.sanityFooter &&
                    data.sanityFooter._rawFooterLinks.map(
                      (menuGroup, index) => (
                        <nav key={index} aria-label={menuGroup.title}>
                          <div>{menuGroup.title}</div>
                          <ul
                            role="menubar"
                            aria-label={menuGroup.title}
                            sx={{
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            {menuGroup.links.map((link, index) => (
                              <li
                                role="none"
                                sx={{
                                  listStyle: "none",
                                  a: {
                                    textDecoration: "none",
                                    ":hover": {
                                      textDecoration: "underline",
                                    },
                                  },
                                }}
                                key={index}
                              >
                                <LinkResolve
                                  link={link.link}
                                  children={link.label}
                                  target="_self"
                                />
                              </li>
                            ))}
                          </ul>
                        </nav>
                      )
                    )}
                </div>
                <div
                  sx={{
                    width: ["100%", "50%", "35%"],
                    maxWidth: ["100%", "370px"],
                  }}
                >
                  <ul
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: "0px",
                      marginTop: ["0px", "0px"],
                      li: {
                        width: "38px",
                        height: "38px",
                        marginRight: "32px",
                        listStyle: "none",
                        img: {
                          width: "100%",
                          height: "auto",
                        },
                        "&:last-child": {
                          marginRight: "0px",
                        },
                      },
                    }}
                  >
                    {data.sanityFooter &&
                      data.sanityFooter._rawSocialMedia.map(
                        (socialGroup, index) => (
                          <li key={index}>
                            <a
                              href={socialGroup.link.url}
                              aria-label={socialGroup.link.arialLabel}
                              sx={{
                                ":hover": {
                                  opacity: "0.5",
                                },
                              }}
                            >
                              {returnSocialImage(socialGroup.icon)}
                            </a>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </div>
            </Container>
          </div>
          <div
            sx={{
              padding: "30px 0px 0px 0px",
            }}
          >
            <Container>
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                              <h3
                              sx={{
                                maxWidth:"600px",
                              }}
                              >
              We work with hundreds of properties in St. Louis, the surrounding areas, and the entire Midwest Region - too many to list here. <Link to="/get-started">Click here</Link> for a free quote
              </h3>
                <h2>Contact Corporate Housing St. Louis Missouri</h2>
                <Link
                  sx={{
                    variant: "buttons.primarySolid",
                  }}
                  to="/contact-us"
                >
                  Contact Us
                </Link>
                <div
                  sx={{
                    maxWidth: "500px",
                    fontSize: "16px",
                    lineHeight: "26px",
                    color: "#656565",
                    marginTop: "20px",
                  }}
                >
                  <div>St Louis Corporate Housing</div>
                  <div>100 Chesterfield Business Parkway, Suite 200B</div>
                  <div>Chesterfield, MO 63005</div>
                </div>
                <div
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "40px",
                    a: {
                      textDecoration: "none",
                      fontSize: "13px",
                      color: "black",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                      "&:nth-child(1)": {
                        borderRight: "thin solid",
                        borderColor: "black",
                        marginRight: "15px",
                        paddingRight: "15px",
                      },
                    },
                  }}
                >
                  <a href="mailto:info@stlch.com">info@stlch.com</a>
                  <a href="tel:314-800-5773">314-800-5773</a>
                </div>
              </div>
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginTop: "60px",
                }}
              >
                <div
                  sx={{
                    width: ["100%", "100%", "100%"],
                    display: "flex",
                    marginTop: ["0px", "0px"],
                    flexWrap: "wrap",
                    justifyContent: "center",
                    color: "textGray",
                    "> div": {
                      marginRight: "25px",
                      marginBottom: "15px",
                      display: "flex",
                      alignItems: "center",
                    },
                    ul: {
                      margin: "0px",
                      padding: "0px",
                      display: "flex",
                      marginBottom: "15px",
                      alignItems: "center",
                    },
                    li: {
                      marginRight: "25px",
                      listStyle: "none",
                      "&:last-child": {
                        marginRight: "0px",
                      },
                    },
                    a: {
                      color: "#F1F1F1",
                      textDecoration: "none",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    },
                  }}
                >
                  <div>
                    &copy;{new Date().getFullYear()} Corporate Housing St. Louis
                    Missouri All Rights Reserved.
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </footer>
      )}
    />
  );
};

export default Footer;
