/** @jsx jsx */
import { jsx } from "theme-ui";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";
import axios from "axios";
import { useState } from "react";

const MediaContact = ({ value }) => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    success: false,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      setServerState({
        success: true,
      });
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/6687fb5b-71b2-48bb-b6c9-5b36cf992c57",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };
  return (
    <div>
      <Container>
        <div
          sx={{
            display: "flex",
            flexWrap: "wrap",
            padding: "125px 0px",
            justifyContent: "space-between",
            alignItems: !serverState.success ? "flex-start" : "center",
          }}
        >
          <div
            sx={{
              width: ["100%", "100%", "calc(100% - 650px)"],
              maxWidth: ["530px", "500px", "500px"],
              color: "textGray",
              fontSize: "body",
              "h1,h2,h3,h4,h5,h6": {
                color: "primary",
                marginTop: "0px",
                marginBottom: "0px",
              },
              h2: {
                fontSize: "3rem",
                maxWidth: "480px",
              },
            }}
          >
            <PortableText
              value={value.body}
              components={PortableTextComponent}
            />
          </div>
          <div
            sx={{
              width: ["100%", "100%", "615px"],
            }}
          >
            {!serverState.success ? (
              <form
                onSubmit={handleOnSubmit}
                sx={{
                  input: {
                    boxSizing: "border-box",
                    border: "thin solid",
                    borderColor: "primary",
                    borderRadius: "8px",
                    height: "64px",
                    padding: "0px 32px",
                    marginBottom: "32px",
                    fontSize: "body",
                    color: "primary",
                    fontFamily: "primary",
                    "&::placeholder": {
                      color: "primary",
                      fontSize: "body",
                      fontWeight: "normal",
                      fontFamily: "primary",
                    },
                  },
                  textarea: {
                    boxSizing: "border-box",
                    border: "thin solid",
                    borderColor: "primary",
                    borderRadius: "8px",
                    padding: "25px 32px",
                    marginBottom: "32px",
                    fontSize: "body",
                    color: "primary",
                    fontFamily: "primary",
                    fontWeight: "normal",
                    "&::placeholder": {
                      color: "primary",
                      fontSize: "body",
                      fontFamily: "primary",
                      fontWeight: "normal",
                    },
                  },
                }}
              >
                <div
                  sx={{
                    color: "textGray",
                  }}
                >
                  All fields are required.
                </div>
                <div
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    placeholder="First name"
                    title="First name"
                    type="text"
                    required
                    name="first_name"
                    autocomplete="given-name"
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        "Enter required first name here"
                      )
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    sx={{
                      width: ["100%", "100%", "calc(50% - 6px)"],
                    }}
                  />
                  <input
                    placeholder="Last name"
                    title="Last name"
                    type="text"
                    required
                    name="last_name"
                    autocomplete="family-name"
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        "Enter required last name here"
                      )
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    sx={{
                      width: ["100%", "100%", "calc(50% - 6px)"],
                    }}
                  />
                </div>
                <input
                  placeholder="Email address"
                  title="Email address"
                  type="email"
                  required
                  name="email"
                  autocomplete="email"
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Enter required valid email here"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  sx={{
                    width: "100%",
                  }}
                />
                <input
                  placeholder="Company"
                  title="Company"
                  type="text"
                  required
                  name="company"
                  autocomplete="organization"
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Enter required valid company here"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  sx={{
                    width: "100%",
                  }}
                />
                <div
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <textarea
                    sx={{
                      height: "160px",
                    }}
                    required
                    name="message"
                    title="Your message"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Enter required message here")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    placeholder="What are you interested in exploring?"
                  />
                </div>
                <button
                  sx={{
                    variant: "buttons.primaryBlue",
                    width: "168px",
                    fontFamily: "primary",
                  }}
                  type="submit"
                  aria-label="Send press get in touch form"
                >
                  Send
                </button>
              </form>
            ) : (
              <div
                sx={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  fontFamily: "primary",
                  lineHeight: "2rem",
                }}
              >
                <div>Thank you!</div>
                <div>
                  Your message has been sent. We'll reach out to you as soon as
                  possible.
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MediaContact;
