/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";

class Burger extends React.Component {
  clickMe = () => {
    this.props.clickMe();
  };
  render() {
    return (
      <div
        onClick={this.clickMe}
        aria-label="menu"
        sx={{
          display: ["flex", "flex", "none"],
          flexDirection: "column",
          justifyContent: "space-around",
          width: "30px",
          height: this.props.open ? "30px" : "20px",
          background: "transparent",
          border: "none",
          cursor: "pointer",
          padding: "0",
          zIndex: this.props.open ? "1001" : "auto",
          transition: "all 0.3s linear",
          right: [
            this.props.open ? "6%" : "5%",
            this.props.open ? "6%" : "5%",
            "auto",
          ],
          top: [
            this.props.open ? "42px" : "48px",
            this.props.open ? "42px" : "48px",
            "auto",
          ],
          position: [
            this.props.open ? "fixed" : "absolute",
            this.props.open ? "fixed" : "absolute",
            "relative",
          ],
          transformOrigin: "1px",
        }}
      >
        <div
          sx={{
            width: "100%",
            height: "2px",
            background: this.props.open ? "primary" : "#ffffff",
            borderRadius: "10px",
            transition: "all 0.3s linear",
            position: "relative",
            transformOrigin: "1px",
            transform: [
              this.props.open ? "rotate(45deg)" : "rotate(0)",
              this.props.open ? "rotate(45deg)" : "rotate(0)",
              "none",
            ],
          }}
        ></div>
        <div
          sx={{
            width: "100%",
            height: "2px",
            background: this.props.open ? "primary" : "#ffffff",
            borderRadius: "10px",
            transition: "all 0.3s linear",
            position: "relative",
            transformOrigin: "1px",
            opacity: [
              this.props.open ? "0" : "1",
              this.props.open ? "0" : "1",
              "auto",
            ],
            transform: [
              this.props.open ? "translateX(20px)" : "translateX(0)",
              this.props.open ? "translateX(20px)" : "translateX(0)",
              "none",
            ],
          }}
        ></div>
        <div
          sx={{
            width: "100%",
            height: "2px",
            background: this.props.open ? "primary" : "#ffffff",
            borderRadius: "10px",
            transition: "all 0.3s linear",
            position: "relative",
            transformOrigin: "1px",
            transform: [
              this.props.open ? "rotate(-45deg)" : "rotate(0)",
              this.props.open ? "rotate(-45deg)" : "rotate(0)",
              "none",
            ],
          }}
        ></div>
      </div>
    );
  }
}
export default Burger;
