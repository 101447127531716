import { toPlainText } from "@portabletext/react";
import React from "react";

export function toPlainTextTruncate(blocks = [], length) {
  let blockString = null;
  if (blocks) {
    if (Array.isArray(blocks) && blocks.length) {
      blockString = toPlainText(blocks);
    } else if (blocks._type) {
      blockString = toPlainText(blocks);
    } else {
      blockString = blocks;
    }
  }
  let suffix = "...";
  blockString =
    blockString.length < length
      ? blockString
      : `${blockString.substring(
          0,
          blockString.substring(0, length - suffix.length).lastIndexOf(" ")
        )}${suffix}`;

  return blockString;
}
