/** @jsx jsx */
import { jsx } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../sanityConfig";
import { getGatsbyImageData } from "gatsby-source-sanity";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";

const TextImage = ({ value }) => {
  const gatsbyImageData = getGatsbyImageData(
    value.image,
    { maxWidth: 1100 },
    sanityConfig
  );
  return (
    <Container>
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          flexDirection: ["column", "column", "row"],
        }}
        className="text-image-container"
      >
        <div className="text-image-image">
          <GatsbyImage image={gatsbyImageData} />
        </div>
        <div
          className="text-image-text"
          sx={{
            color: "textGray",
            fontSize: "body",
            "h1,h2,h3,h4,h5,h6": {
              color: "primary",
            },
          }}
        >
          <PortableText value={value.text} components={PortableTextComponent} />
        </div>
      </div>
    </Container>
  );
};

export default TextImage;
