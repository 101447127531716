import * as React from "react";

const LinkResolve = ({ link, children, target, ariaLabel }) => {
  let href = "";
  let targetWindow = target ? target : "_blank";
  if (link.url) {
    href = link.url;
  }
  if (link.document_reference) {
    let typePath = "/";
    if (link.document_reference.post_type === "article") {
      typePath = "/articles/";
    }
    if (link.document_reference.post_type === "blog_post") {
      typePath = "/blog/";
    }
    href = typePath + link.document_reference.slug.current;
  }
  return (
    <React.Fragment>
      <a target={targetWindow} href={href} aria-label={ariaLabel}>
        {children}
      </a>
    </React.Fragment>
  );
};

export default LinkResolve;
