/** @jsx jsx */
import { jsx } from "theme-ui";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";
import { useStaticQuery, graphql } from "gatsby";
import ArticleTeaserSmall from "../../components/entities/article/articleRelatedContent";

const RecentArticlesBlock = ({ value }) => {
  const { allSanityArticle } = useStaticQuery(
    graphql`
      query {
        allSanityArticle(
          limit: 4
          sort: { order: DESC, fields: publish_date }
        ) {
          nodes {
            headline
            short_title
            pinned
            _rawIntroText(resolveReferences: { maxDepth: 10 })
            _rawBody(resolveReferences: { maxDepth: 10 })
            slug {
              current
            }
            main_image {
              asset {
                gatsbyImageData(width: 1440)
              }
            }
          }
        }
      }
    `
  );
  return (
    <div>
      <Container>
        {value.body && (
          <div
            sx={{
              borderTop: "thin solid rgba(83, 82, 83, 0.15)",
              textAlign: "center",
              padding: "60px 0px 60px 0px",
              h2: {
                margin: "0px",
              },
            }}
          >
            <PortableText
              value={value.body}
              components={PortableTextComponent}
            />
          </div>
        )}
        <ul
          sx={{
            padding: "0px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {allSanityArticle.nodes.map((article, index) => (
            <li
              key={index}
              sx={{
                listStyle: "none",
                width: ["100%", "100%", "calc(50% - 28px)"],
                marginBottom: "64px",
              }}
            >
              <ArticleTeaserSmall article={article} />
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};

export default RecentArticlesBlock;
