/** @jsx jsx */
import { jsx } from "theme-ui";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";
import LinkResolve from "./linkResolve";
import "../../scss/component/newsBlock.scss";

const NewsBlock = ({ value }) => {
  return (
    <div
      id={value.id ? value.id : ""}
      sx={{
        backgroundColor: "#E5EAF1",
        padding: "100px 0px",
      }}
    >
      <Container>
        <div
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <div
            sx={{
              width: ["100%", "100%", "50%"],
            }}
          >
            <h2
              sx={{
                fontSize: ["2rem", "2rem", "3rem"],
                margin: "0px",
              }}
            >
              {value.title}
            </h2>
            {value.link && (
              <div
                sx={{
                  a: {
                    variant: "buttons.primaryBlue",
                    marginTop: "15px",
                    display: "inline-block",
                  },
                }}
              >
                <LinkResolve
                  link={value.link.link}
                  children={value.link.label}
                  target="_self"
                  ariaLabel={value.link.link.arialLabel}
                />
              </div>
            )}
          </div>
          <div
            sx={{
              width: ["100%", "100%", "50%"],
              maxWidth: ["100%", "100%", "420px"],
              color: "textGray",
              fontSize: "body",
            }}
          >
            <PortableText
              value={value.body}
              components={PortableTextComponent}
            />
          </div>
        </div>
      </Container>
      <div
        className="news-slide-container"
        sx={{
          ul: {
            margin: "0px",
            padding: "0px",
          },
          li: {
            listStyle: "none",
          },
        }}
      >
        <ul className="news-slide-inner first">
          {value.logos.map((logo, index) => (
            <li key={index}>
              <img src={logo.asset.url} />
            </li>
          ))}
        </ul>
        <ul className="news-slide-inner">
          {value.logos.map((logo, index) => (
            <li key={index}>
              <img src={logo.asset.url} />
            </li>
          ))}
        </ul>
        <ul className="news-slide-inner delay">
          {value.logos.map((logo, index) => (
            <li key={index}>
              <img src={logo.asset.url} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NewsBlock;
