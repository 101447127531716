/** @jsx jsx */
import { jsx } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../../sanityConfig";
import { getGatsbyImageData } from "gatsby-source-sanity";
import PlaceHolder from "../../../images/Placeholder2.svg";

const TeamMember = ({ entity }) => {
  const gatsbyTopicIconImageData = getGatsbyImageData(
    entity.image,
    { maxWidth: 100 },
    sanityConfig
  );
  return (
    <div>
      {entity.image ? (
        <GatsbyImage image={gatsbyTopicIconImageData} />
      ) : (
        <img src={PlaceHolder} />
      )}
      {entity.fullName && <h3>{entity.fullName}</h3>}
      {entity.title && <div>{entity.title}</div>}
    </div>
  );
};

export default TeamMember;
