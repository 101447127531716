import React from "react";
import TeamMember from "../entities/teamMember/teamMember";
import PropertyTeaser from "../entities/property/propertyTeaser";
const DocReference = ({ value }) => {
  if (value.documentReference?._type === "teamMember") {
    return <TeamMember entity={value.documentReference} />;
  }
  if (value.documentReference?._type === "property") {
    return <PropertyTeaser entity={value.documentReference} />;
  }
  return null;
};

export default DocReference;
