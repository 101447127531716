/** @jsx jsx */
import { jsx } from "theme-ui";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";
import Faq from "../serializers/faq";
import { useState } from "react";

const FaqBlock = ({ value }) => {
  const [clicked, setClicked] = useState("0");
  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };
  return (
    <div id={value.id ? value.id : ""}>
      <Container>
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: ["column", "row"],
            padding: "165px 0px 145px 0px",
            flexWrap: "wrap",
          }}
        >
          <div
            sx={{
              width: ["100%", "100%", "calc(100% - 700px)"],
              marginBottom: ["80px", "80px", "0px"],
              maxWidth: ["100%", "100%", "100%"],
            }}
          >
            <div
              sx={{
                color: "textGray",
                fontSize: "body",
                ".cta": {
                  lineHeight: "1.5",
                },
                h1: {
                  fontSize: ["2.75rem", "3.5rem"],
                  margin: "0px 0px 10px 0px",
                  lineHeight: "3.4rem",
                  maxWidth: "480px",
                  color: "primary",
                },
              }}
              className="hero-copy-container"
            >
              <PortableText
                value={value.body}
                components={PortableTextComponent}
              />
            </div>
          </div>

          <div
            className="faqs-container"
            sx={{
              width: ["100%", "100%", "615px"],
              position: "relative",
              display: "flex",
              alignItems: "center",
              "&:last-child": {
                marginBottom: "0px",
              },
            }}
          >
            <div>
              {value.faqs.map((faq, index) => (
                <div>
                  <Faq
                    key={index}
                    faq={faq}
                    onToggle={() => handleToggle(index)}
                    active={clicked === index}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FaqBlock;
